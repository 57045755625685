/* eslint-disable prefer-rest-params */
import { LibCategories } from "src/utils/consent";
import { loadScript } from "src/utils/scriptLoader";
import { getPinterestPixelId } from "src/utils/userSegment/pixelUtils";

declare const window: Window & {
  pintrk?: any;
};

export function loadPinterest() {
  return loadScript("https://s.pinimg.com/ct/core.js", [
    LibCategories.targetingCookies,
  ]).then(() => {
    if (!window.pintrk) {
      window.pintrk = function pintrk() {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments));
      };
      const n = window.pintrk;
      n.queue = [];
      n.version = "3.0";
    }
    const { pintrk } = window;
    const tagId = getPinterestPixelId();
    pintrk("load", tagId);
    pintrk("page", { page_name: "Noom", page_category: "health" });
    pintrk("track", "pagevisit");
  });
}

import { MED_PINTEREST_TAG_ID, WEIGHT_PINTERST_TAG_ID } from "../constants";
import { shouldFireMedPixel } from "./features";

/**
 * Determines which pinterest tag we need to fire.
 */
export function getPinterestPixelId() {
  if (shouldFireMedPixel()) {
    return MED_PINTEREST_TAG_ID;
  }
  return WEIGHT_PINTERST_TAG_ID;
}
